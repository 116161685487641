import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Contacts from './pages/Contacts';
import Terms from './pages/terms';
import Policy from './pages/Policy';
import Gallery from './pages/Gallery';
import Services from './pages/Services';
import ServicesInside from './pages/ServicesInside';
import page404 from './pages/404';
import { QueryClient, QueryClientProvider } from "react-query";

import PrivateRoute from "./pages/PrivateRoute";
import Dashboard from "./pages/Dashboard";
import Login from "./auth/Login";
import { AuthProvider } from "./contexts/AuthContext";
import Bookings from "./pages/Bookings";
import Confirmation from "./pages/confirmation";

function App() {
    const queryClient = new QueryClient();

    return (
        <AuthProvider>
            <QueryClientProvider client={queryClient}>
                <Router>
                    <Switch>
                        <PrivateRoute exact path="/admin" component={Dashboard}/>
                        <Route
                            exact
                            path={`${process.env.PUBLIC_URL + "/"}`}
                            component={Home}
                        />
                        <Route
                            exact
                            path={`${process.env.PUBLIC_URL + "/confirmation/:id"}`}
                            component={Confirmation}
                        />
                        <Route
                            exact
                            path={`${process.env.PUBLIC_URL + "/about-us"}`}
                            component={About}
                        />
                        <Route
                            exact
                            path={`${process.env.PUBLIC_URL + "/contacts"}`}
                            component={Contacts}
                        />
                        <Route
                            exact
                            path={`${process.env.PUBLIC_URL + "/bookings"}`}
                            component={Bookings}
                        />
                        <Route
                            exact
                            path={`${process.env.PUBLIC_URL + "/terms"}`}
                            component={Terms}
                        />
                        <Route
                            exact
                            path={`${process.env.PUBLIC_URL + "/policy"}`}
                            component={Policy}
                        />
                        <Route
                            exact
                            path="/login"
                            component={Login}/>
                        <Route
                            exact
                            path={`${process.env.PUBLIC_URL + "/gallery"}`}
                            component={Gallery}
                        />
                        <Route
                            exact
                            path={`${process.env.PUBLIC_URL + "/services"}`}
                            component={Services}
                        />
                        <Route
                            exact
                            path={`${process.env.PUBLIC_URL + "/services-inside"}`}
                            component={ServicesInside}
                        />
                        <Route exact component={page404} />
                    </Switch>
                </Router>
            </QueryClientProvider>
        </AuthProvider>
    );
}

export default App;
