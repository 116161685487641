import React from 'react';

const AboutVideo = () => {
    return (
        <div id="about-us-img" className="block">
            <div className="about-img">
                <video width="100%" height="100%" playsInline autoPlay muted loop id="myVideoAbout">
                    <source src="assets/img/barberCut.mp4" type="video/mp4"/>
                </video>
            </div>
        </div>
    );
};

export default AboutVideo;
