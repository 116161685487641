import React, { Fragment } from 'react';
import MetaTags from 'react-meta-tags';

import Loading from '../blocks/loading/Loading';
import Header from '../blocks/header/Header';
import PageTitleServicesInside from '../blocks/page-title/PageTitleServicesInside';
import Footer from '../blocks/footer/Footer';
import BackToServices from '../components/button/BackToServices';

const ServicesInside = () => {
  document.body.classList.add('single');
  document.body.classList.add('single-adveits_services');

  return (
    <Fragment>
      <MetaTags>
        <meta charSet="UTF-8" />
        <title>Priser | Studio 4 Eriksberg</title>

        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta
          name="description"
          content="barbershop studio 4 priser studio 4 eriksberg barbershop studio 4 eriksberg bästa frisör i göteborg, gbg frisör, eriksberg frisör, frisör eriksberg salong eriksberg, hårklippning eriksberg, barbershop eriksberg, barbershop göteborg, barbershop gbg, hårklippning eriksberg, hårklippning studio 4 eriksberg, hårklippning göteborg, hårklippning gbg, salong studio 4, eriksberg barbershop, göteborg barbershop, studio 4 eriksberg, eriksberg damklipp, eriksberg herklipp, boka frisör eriksberg, eriksberg boka frisör"
        />
        <meta
          name="keywords"
          content="barbershop pris studio 4 eriksberg, barbershop studio 4 eriksberg bästa frisör i göteborg, gbg frisör, eriksberg frisör, frisör eriksberg salong eriksberg, hårklippning eriksberg, barbershop eriksberg, barbershop göteborg, barbershop gbg, hårklippning eriksberg, hårklippning studio 4 eriksberg, hårklippning göteborg, hårklippning gbg, salong studio 4, eriksberg barbershop, göteborg barbershop, studio 4 eriksberg, eriksberg damklipp, eriksberg herklipp, boka frisör eriksberg, eriksberg boka frisör"
        />
        <meta name="robots" content="index, follow, noodp" />
        <meta name="googlebot" content="index, follow" />
        <meta name="google" content="notranslate" />
        <meta name="format-detection" content="telephone=no" />
      </MetaTags>

      <Loading />

      <Header logoColor="dark" />

      <main id="main" className="site-main">
        <PageTitleServicesInside />

        <div id="page-content" className="spacer p-top-xl">
          <div className="wrapper">
            <div className="content">
              <div id="single">
                <div id="single-content">
                  <div id="price-list" className="block spacer">
                    <div className="price-list">
                      <div className="price-list-items">
                        <div className="price-list-item">
                          <div className="price-list-item-title">
                            <h3>Speciella behandlingar i salongen</h3>
                          </div>

                          <ul className="price-list-item-list-group list-group">
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                              <span className="list-group-title">
                                Ansiktsbehandling (ansiktsånga med mask)
                              </span>
                              <span className="list-group-price">100 kr</span>
                            </li>
                          </ul>
                        </div>

                        <div className="price-list-item">
                          <div className="price-list-item-title">
                            <h3>Hår och skägg</h3>
                          </div>

                          <ul className="price-list-item-list-group list-group">
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                              <span className="list-group-title">
                                Herrklippning
                              </span>
                              <span className="list-group-price">390 kr</span>
                            </li>

                            <li className="list-group-item d-flex justify-content-between align-items-center">
                              <span className="list-group-title">
                                Damklippning
                              </span>
                              <span className="list-group-price">490 kr</span>
                            </li>

                            <li className="list-group-item d-flex justify-content-between align-items-center">
                              <span className="list-group-title">
                                Hår och skägg
                              </span>
                              <span className="list-group-price">590 kr</span>
                            </li>

                            <li className="list-group-item d-flex justify-content-between align-items-center">
                              <span className="list-group-title">Skägg</span>
                              <span className="list-group-price">250 kr</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <BackToServices />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </Fragment>
  );
};

export default ServicesInside;
