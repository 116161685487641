import React from 'react';

const ContactsUs = () => {
    return (
        <section id="contact-us" className="block">
            <div className="bg-blue text-tertiary spacer p-top-xl p-bottom-xl">
                <div className="wrapper text-center">
                    <div className="title">
                        <h3 className="text-uppercase">Välkommen på drop in eller tidsbokning</h3>
                    </div>
                    <div style={ { marginBottom: "1rem" }}><a href={ process.env.PUBLIC_URL + "/bookings" } className="btn btn-outline-tertiary text-uppercase">Boka tid</a></div>
                    <a href={ process.env.PUBLIC_URL + "/contacts" } className="btn btn-outline-tertiary text-uppercase">Hitta oss</a>
                </div>
            </div>
        </section>
    );
};

export default ContactsUs;
