import React from 'react';

const GoogleMapsIframe = () => {
    return (
        <iframe
            title="Barbershop Studio 4 Eriksberg"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2131.819816974295!2d11.915649216007134!3d57.70253098111951!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x464f8d03f957cc9b%3A0x1ef3969aaecfc27c!2sStudio%204%20Eriksberg!5e0!3m2!1ssv!2sse!4v1612003415061!5m2!1ssv!2sse"
            width="100%"
            height="500"
            frameBorder="0"
            allowFullScreen=""
            aria-hidden="false"
            tabIndex="0">
        </iframe>
    );
};

export default GoogleMapsIframe;
