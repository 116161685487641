import React, { useState } from 'react';
import { database } from '../../firebase';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import { useQueryClient } from 'react-query';

const CustomModal = (props) => {
  const queryClient = useQueryClient();
  const [name, setName] = useState('');
  const [phoneNo, setPhoneNo] = useState('');
  const history = useHistory();

  const reservData = () => {
    const uid = uuidv4();
    props.type === 'boka'
      ? database.ref('boka/' + uid).set({
          username: name,
          uid: props.uid,
          phone: phoneNo,
          is30minuteSlot: props.is30minuteSlot,
          timeSlotReservedAt: props?.timeSlotReservedAt,
          reservedAt: props.start.format('DD/MM/yy- HH:mm'),
          NoOfDay: props.start.dayOfYear(),
        })
      : database.ref('boka1/' + uid).set({
          username: name,
          uid: props.uid,
          phone: phoneNo,
          is30minuteSlot: props.is30minuteSlot,
          timeSlotReservedAt: props?.timeSlotReservedAt,
          reservedAt: props.start.format('DD/MM/yy- HH:mm'),
          NoOfDay: props.start.dayOfYear(),
        });
    axios.post('https://kingstone-kutz.vercel.app/api/sendgrid', {
      firstName: '',
      '': '',
      email: 'studio4eriksberg@gmail.com',
      message: props.start.format('DD/MM/yy- HH:mm'),
    });
    history.push(`/confirmation/${props.start.format('LLLL')}`);
    queryClient.invalidateQueries('sightings');
  };

  const handlePhone = (e) => {
    const value = e;
    const regex = /^((((0{2}?)|(\+){1})46)|0)7[\d]{8}$/;
    setPhoneNo(e);
    if (!regex.test(value.toString())) {
      return false;
    }
  };

  return (
    <form onSubmit={reservData}>
      <h6 align={'center'} style={{ marginTop: '20px' }}>
        Valt datum {props.start.format('LLLL')}
      </h6>
      <label
        style={{ marginLeft: '10px', marginTop: '2rem' }}
        htmlFor="costumer-name"
        className=""
      >
        Namn
      </label>
      <input
        className="form-control"
        type="text"
        placeholder="För- och efternamn"
        defaultValue=""
        id="costumer-name"
        onChange={(e) => setName(e.target.value)}
        required={true}
      />

      <label
        style={{ marginTop: '2rem', marginLeft: '10px' }}
        htmlFor="costumer-phone"
        className=""
      >
        Telefonnummer
      </label>
      <input
        className="form-control"
        type="tel"
        placeholder="tel"
        id="costumer-phone"
        onChange={(e) => handlePhone(e.target.value)}
        required={true}
      />

      <div style={{ marginTop: '1rem' }}>
        <input
          type="checkbox"
          id="sparauppgifter"
          name="sparauppgifter"
          value="sparauppgifter"
          required={true}
        />
        <label htmlFor="sparauppgifter">
          Jag godkänner att mina uppgifter sparas
        </label>
      </div>

      <div className=" d-flex justify-content-center">
        <button
          className="btn-primary btn-lg"
          style={{ width: '200px', marginTop: '20px' }}
        >
          Boka tid
        </button>
      </div>
    </form>
  );
};

export default CustomModal;
