import React, { Fragment } from 'react';
import MetaTags from 'react-meta-tags';

import Loading from '../blocks/loading/Loading';
import Header from '../blocks/header/Header';
import Footer from '../blocks/footer/Footer';

import PageTitleContacts from '../blocks/page-title/PageTitleContacts';

import GoogleMaps from '../blocks/google-maps/GoogleMapsIframe';
import ContactsInside from '../blocks/contacts/Contacts';

const Contacts = () => {
    document.body.classList.add( 'page' );

    return (
        <Fragment>
            <MetaTags>
                <meta charSet="UTF-8" />
                <title>Kontakta oss studio 4 eriksberg</title>

                <meta httpEquiv="x-ua-compatible" content="ie=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content="barbershop studio 4 eriksberg bästa frisör i göteborg, gbg frisör, eriksberg frisör, frisör eriksberg salong eriksberg, hårklippning eriksberg, barbershop eriksberg, barbershop göteborg, barbershop gbg, hårklippning eriksberg, hårklippning studio 4 eriksberg, hårklippning göteborg, hårklippning gbg, salong studio 4, eriksberg barbershop, göteborg barbershop, studio 4 eriksberg, eriksberg damklipp, eriksberg herklipp, boka frisör eriksberg, eriksberg boka frisör" />
                <meta name="keywords" content="barbershop studio 4 eriksberg bästa frisör i göteborg, gbg frisör, eriksberg frisör, frisör eriksberg salong eriksberg, hårklippning eriksberg, barbershop eriksberg, barbershop göteborg, barbershop gbg, hårklippning eriksberg, hårklippning studio 4 eriksberg, hårklippning göteborg, hårklippning gbg, salong studio 4, eriksberg barbershop, göteborg barbershop, studio 4 eriksberg, eriksberg damklipp, eriksberg herklipp, boka frisör eriksberg, eriksberg boka frisör" />
                <meta name="robots" content="index, follow, noodp" />
                <meta name="googlebot" content="index, follow" />
                <meta name="google" content="notranslate" />
                <meta name="format-detection" content="telephone=no" />
            </MetaTags>

            <Loading />

            <Header logoColor="dark" />

            <main id="main" className="site-main">
                <PageTitleContacts />
                <div id="page-content" className="spacer p-top-xl">
                    <div className="wrapper">
                        <div className="content">
                            <div className="clearfix">
                                <GoogleMaps />

                                <div className="spacer p-top-xl" >
                                    <div className="title">
                                        <h2 className="text-uppercase">Kontakta oss</h2>
                                    </div>

                                    <div className="row gutter-width-sm with-pb-xl spacer p-top-lg">
                                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                                            <ContactsInside />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            <Footer />
        </Fragment>
    );
};

export default Contacts;
