import React, { Component, Fragment } from 'react';
import SwipeBox from 'fslightbox-react';

class SwipeBoxComponent extends Component {

    state = {
        isVisible: false,
        slide: 0,
    };
    showSlide = ( slide, event ) => {
        event.preventDefault();
        this.setState( {
            isVisible: !this.state.isVisible,
            slide: slide
        } );
    };

    render() {
        return (
            <Fragment>
                <SwipeBox
                    toggler={ this.state.isVisible }
                    slide={ this.state.slide }
                    urls={ [
                        'assets/img/galenfade.mp4',
                        'assets/img/galenfade2.mp4',
                        'assets/img/ansiktsbehandling.mp4',
                        'https://i.ibb.co/L6xxT9c/IMG-0454.jpg',
                        'https://i.ibb.co/XzSsRm6/smoothfade.jpg',
                        'https://i.ibb.co/cL44pq0/image.jpg',
                        'https://i.ibb.co/PmLbj53/image2.jpg',
                        'https://i.ibb.co/Q97v2p8/produkter.jpg',
                        'https://i.ibb.co/x5H75Cg/IMG-0202.jpg',
                        'https://i.ibb.co/qyVWNjR/utsidan.jpg'
                    ] }
                />
                { <div className={this.props.render === true ? "col-xl-4 col-lg-4 col-md-4 col-sm-4" : "col-xl-6 col-lg-6 col-md-6 col-sm-6"}>
                    <a
                        onClick={ ( event ) => this.showSlide( 1, event ) }
                        title="Fade | Video 1"
                        className="gallery-item swipebox"
                        href="assets/img/galenfade.mp4"
                        rel="gallery"
                    >
                        <div style={ this.props.render === true && window.innerWidth > 700 ? { maxWidth: "780px", minHeight: "350px", maxHeight: "600px"} : { maxWidth: "780px", maxHeight: "650px" } }>
                            <video width="100%" playsInline autoPlay muted loop id="myVideo3">
                                <source src="assets/img/galenfade.mp4" type="video/mp4"/>
                            </video>
                        </div>

                        <div className="img-bg-color"/>
                    </a>
                </div>}

                { <div className={this.props.render === true ? "col-xl-4 col-lg-4 col-md-4 col-sm-4" : "col-xl-6 col-lg-6 col-md-6 col-sm-6"}>
                    <a
                        onClick={ ( event ) => this.showSlide( 2, event ) }
                        title="Fade | Video 3" ref={ this.swipeImg }
                        className="gallery-item swipebox"
                        href="assets/img/galenfade2.mp4"
                        rel="gallery"
                    >
                        <div className="object-fit-cover" style={ this.props.render === true && window.innerWidth > 700 ? { maxWidth: "780px", minHeight: "350px", maxHeight: "600px" } : { maxWidth: "780px", maxHeight: "650px" } }>
                            <video width="100%" playsInline autoPlay muted loop id="myVideo1">
                                <source src="assets/img/galenfade2.mp4" type="video/mp4"/>
                            </video>
                        </div>

                        <div className="img-bg-color"/>
                    </a>
                </div>}

                { <div className={this.props.render === true ? "col-xl-4 col-lg-4 col-md-4 col-sm-4" : "col-xl-6 col-lg-6 col-md-6 col-sm-6"}>
                    <a
                        onClick={ ( event ) => this.showSlide( 3, event ) }
                        title="Ansiktsbehandling | Video 2"
                        className="gallery-item swipebox"
                        href="assets/img/ansiktsbehandling.mp4"
                        rel="gallery"
                    >
                        <div className="object-fit-cover" style={ this.props.render === true && window.innerWidth > 700 ? { maxWidth: "780px", maxHeight: "600px" } : { maxWidth: "780px", maxHeight: "550px" } }>
                            <video width="100%" autoPlay playsInline muted loop id="myVideo2">
                                <source src="assets/img/ansiktsbehandling.mp4" type="video/mp4"/>
                            </video>
                        </div>

                        <div className="img-bg-color"/>
                    </a>
                </div>}

                {this.props.render === false && <div className={this.props.render === true ? "col-xl-4 col-lg-4 col-md-4 col-sm-4" : "col-xl-6 col-lg-6 col-md-6 col-sm-6"}>
                    <a
                        onClick={ ( event ) => this.showSlide( 4, event ) }
                        title="Fade | Fade 1"
                        className="gallery-item swipebox"
                        href="https://i.ibb.co/L6xxT9c/IMG-0454.jpg"
                        rel="gallery"
                    >
                        <div className="img object-fit">
                            <div className="object-fit-cover">
                                <img src="https://i.ibb.co/L6xxT9c/IMG-0454.jpg" alt="Studio 4 Eriksberg" />
                            </div>
                        </div>

                        <div className="img-bg-color"/>
                    </a>
                </div>}

                {this.props.render === false && <div className={this.props.render === true ? "col-xl-4 col-lg-4 col-md-4 col-sm-4" : "col-xl-6 col-lg-6 col-md-6 col-sm-6"}>
                    <a
                        onClick={ ( event ) => this.showSlide( 5, event ) }
                        title="Fade 2 | Fade 2"
                        className="gallery-item swipebox"
                        href="https://i.ibb.co/XzSsRm6/smoothfade.jpg"
                        rel="gallery"
                    >
                        <div className="img object-fit">
                            <div className="object-fit-cover">
                                <img src="https://i.ibb.co/XzSsRm6/smoothfade.jpg" alt="Studio 4 Eriksberg" />
                            </div>
                        </div>

                        <div className="img-bg-color"/>
                    </a>
                </div>}

                {this.props.render === false && <div className={this.props.render === true? "col-xl-4 col-lg-4 col-md-4 col-sm-4" : "col-xl-6 col-lg-6 col-md-6 col-sm-6"}>
                    <a
                        onClick={ ( event ) => this.showSlide( 6, event ) }
                        title="Entre | Bild 1" ref={ this.swipeImg }
                        className="gallery-item swipebox"
                        href="https://i.ibb.co/cL44pq0/image.jpg"
                        rel="gallery"
                    >
                        <div className="img object-fit">
                            <div className="object-fit-cover">
                                <img src="https://i.ibb.co/cL44pq0/image.jpg" alt="Studio 4 Eriksberg" />
                            </div>
                        </div>
                        <div className="img-bg-color"/>
                    </a>
                </div>}

                {this.props.render === false && <div className={this.props.render === true? "col-xl-4 col-lg-4 col-md-4 col-sm-4" : "col-xl-6 col-lg-6 col-md-6 col-sm-6"}>
                    <a
                        onClick={ ( event ) => this.showSlide( 7, event ) }
                        title="Sidovy | Bild 2"
                        className="gallery-item swipebox"
                        href="https://i.ibb.co/PmLbj53/image2.jpg" rel="gallery"
                    >
                        <div className="img object-fit">
                            <div className="object-fit-cover">
                                <img src="https://i.ibb.co/PmLbj53/image2.jpg" alt="Studio 4 Eriksberg" />
                            </div>
                        </div>

                        <div className="img-bg-color"/>
                    </a>
                </div>}
                {this.props.render === false &&  <div className={this.props.render === true ? "col-xl-4 col-lg-4 col-md-4 col-sm-4" : "col-xl-6 col-lg-6 col-md-6 col-sm-6"}>
                    <a
                        onClick={ ( event ) => this.showSlide( 8, event ) }
                        title="Produkter | Bild 3"
                        className="gallery-item swipebox"
                        href="https://i.ibb.co/Q97v2p8/produkter.jpg"
                        rel="gallery"
                    >
                        <div className="img object-fit">
                            <div className="object-fit-cover">
                                <img src="https://i.ibb.co/Q97v2p8/produkter.jpg" alt="Studio 4 Eriksberg" />
                            </div>
                        </div>

                        <div className="img-bg-color"/>
                    </a>
                </div>}

                {this.props.render === false && <div className={this.props.render === true ? "col-xl-4 col-lg-4 col-md-4 col-sm-4" : "col-xl-6 col-lg-6 col-md-6 col-sm-6"}>
                    <a
                        onClick={ ( event ) => this.showSlide( 9, event ) }
                        title="Salongen | Image 4"
                        className="gallery-item swipebox"
                        href="https://i.ibb.co/x5H75Cg/IMG-0202.jpg"
                        rel="gallery"
                    >
                        <div className="img object-fit">
                            <div className="object-fit-cover">
                                <img src="https://i.ibb.co/x5H75Cg/IMG-0202.jpg" alt="Studio 4 Eriksberg" />
                            </div>
                        </div>

                        <div className="img-bg-color"/>
                    </a>
                </div>}

                {this.props.render === false && <div className={this.props.render === true ? "col-xl-4 col-lg-4 col-md-4 col-sm-4" : "col-xl-6 col-lg-6 col-md-6 col-sm-6"}>
                    <a
                        onClick={ ( event ) => this.showSlide( 10, event ) }
                        title="Utsidan | Image 5"
                        className="gallery-item swipebox"
                        href="https://i.ibb.co/qyVWNjR/utsidan.jpg"
                        rel="gallery"
                    >
                        <div className="img object-fit">
                            <div className="object-fit-cover">
                                <img src="https://i.ibb.co/qyVWNjR/utsidan.jpg" alt="Studio 4 Eriksberg" />
                            </div>
                        </div>

                        <div className="img-bg-color"/>
                    </a>
                </div>}
            </Fragment>
        );
    };
};

export default SwipeBoxComponent;
