import React from 'react';

const customEvent = (props) => {
    return (
        <div className="event">
            <p style={{marginTop: "10px", fontSize: "16px"}}> {props.start.format("HH:mm")} </p>
        </div>
    )
};

export default customEvent;
