import React from 'react';

const Contacts = () => {
    return (
        <div className="contacts">
            <div className="contacts-items">
                <div className="contacts-item">
                    <div className="contacts-item-title">
                        <h6>Adress</h6>
                    </div>
                    <div className="contacts-item-description">
                        <p style={ { marginTop: "1.2rem" }}>Östra Eriksbergsgatan 73, 417 62 Göteborg</p>
                    </div>
                </div>

                <div className="contacts-item">
                    <div className="contacts-item-title">
                        <h6>Telefon</h6>
                    </div>
                    <div className="contacts-item-description">
                        <p style={ { marginTop: "1.2rem" }}>
                            <a href="tel:031-382 35 55">031-382 35 55</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contacts;
