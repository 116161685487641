import React from 'react';
import moment from 'moment'
const customHeaderCell = (props) => {
 const CurrentDat = props.date.format("DDD") === moment().format("DDD")
    return (
        <div style={ CurrentDat ? {fontWeight: "bold"} : {fontWeight: "100"} }>
            <span>
                {props.date.format("dddd")}
            </span>
            <span style={{ display: "block", marginTop: "-0.5rem" }}>{props.date.format("D/M")}</span>
        </div>
    )};

export default customHeaderCell;
