import React from 'react';

const AboutOurTeam = () => {
    return (
        <div id="our-team" className="block spacer p-top-xl">
            <div className="title">
                <h2 className="hr">Frisör</h2>
            </div>

            <div className="row gutter-width-sm with-pb-md">
                <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4">
                    <div className="team-member">
                        <div className="img object-fit">
                            <div className="object-fit-cover">
                                <img src="https://i.ibb.co/cXWtk0S/weam.jpg" alt="Weam"/>
                            </div>
                        </div>

                        <div className="team-member-content">
                            <div className="team-member-position">
                                <p>Barberare</p>
                            </div>

                            <h4 className="team-member-t-head">Weam</h4>

                            <div className="team-member-description">
                                <p>En erfaren och träffsäker barberare med över 10 års erfarenhet inom yrket. Ett gott öga för stil som skräddarsyr efter vad som passar i samråd med kunden.</p>
                            </div>
                        </div>

                        <nav className="team-member-nav-items">
                            <ul className="nav">
                                <li className="nav-item">
                                    <a title="Facebook" href="https://www.facebook.com/profile.php?id=100008859370848"><i className="fab fa-facebook-f"/></a>
                                </li>

                                <li className="nav-item">
                                    <a title="Instagram" href="https://www.instagram.com/weam.alsafadi/"><i className="fab fa-instagram"/></a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutOurTeam;
