import React, { Fragment } from 'react';
import MetaTags from 'react-meta-tags';

import Loading from '../blocks/loading/Loading';
import Header from '../blocks/header/Header';
import Footer from '../blocks/footer/Footer';

import PageTitleHome from '../blocks/page-title/PageTitleHome';
import ContactsUs from "../blocks/contacts/ContactUs";
import ServicesHome from '../blocks/services/ServicesHome';
import AboutsUs from '../blocks/about/AboutUs';
import OpeningHours from '../blocks/opening-hours/Opening-hours';
import GoogleMapsIframe from '../blocks/google-maps/GoogleMapsIframe';
import GalleryContent from '../blocks/gallery/GalleryContent';


const Home = () => {
    document.body.classList.add( 'home' );
    document.body.classList.add( 'header-absolute-true' );

    return (
        <Fragment>
            <MetaTags>
                <meta charSet="UTF-8" />
                <title>Barbershop | Studio 4 Eriksberg</title>

                <meta httpEquiv="x-ua-compatible" content="ie=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content="barbershop hemsida studio 4 eriksberg barbershop studio 4 eriksberg bästa frisör i göteborg, gbg frisör, eriksberg frisör, frisör eriksberg salong eriksberg, hårklippning eriksberg, barbershop eriksberg, barbershop göteborg, barbershop gbg, hårklippning eriksberg, hårklippning studio 4 eriksberg, hårklippning göteborg, hårklippning gbg, salong studio 4, eriksberg barbershop, göteborg barbershop, studio 4 eriksberg, eriksberg damklipp, eriksberg herklipp, boka frisör eriksberg, eriksberg boka frisör" />
                <meta name="keywords" content="barbershop studio 4 eriksberg, barbershop studio 4 eriksberg, bästa frisör i göteborg, gbg frisör, eriksberg frisör, frisör eriksberg salong eriksberg, hårklippning eriksberg, barbershop eriksberg, barbershop göteborg, barbershop gbg, hårklippning eriksberg, hårklippning studio 4 eriksberg, hårklippning göteborg, hårklippning gbg, salong studio 4, eriksberg barbershop, göteborg barbershop, studio 4 eriksberg, eriksberg damklipp, eriksberg herklipp, boka frisör eriksberg, eriksberg boka frisör" />
                <meta name="robots" content="index, follow, noodp" />
                <meta name="googlebot" content="index, follow" />
                <meta name="google" content="notranslate" />
                <meta name="format-detection" content="telephone=no" />
            </MetaTags>

            <Loading />

            <Header logoColor="light" />

            <main id="main" className="site-main content-no-spacing">
                <div className="content">
                    <div className="clearfix">
                        <PageTitleHome />

                        <ContactsUs />

                        <ServicesHome />

                        <AboutsUs />

                        <div id="img" className="block spacer p-top-xl">
                            <GalleryContent render={true} />
                        </div>

                        <OpeningHours/>

                        <GoogleMapsIframe />
                    </div>
                </div>
            </main>

            <Footer />
        </Fragment>
    );
};

export default Home;
