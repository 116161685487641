import { database } from '../../../firebase';

export async function fetchBookingSlot() {
  let is40minutesBookingSlot;
  await database.ref('/is40minutes').once('value', (snapshot) => {
    snapshot.forEach((childSnapshot) => {
      is40minutesBookingSlot = childSnapshot.val().value;
    });
  });
  return is40minutesBookingSlot;
}
