import React from 'react';

const PageTitleTerms = () => {
    return (
        <div id="page-title">
            <div className="wrapper text-center">
                <h1 className="large text-uppercase">Villkor</h1>
            </div>
        </div>
    );
};

export default PageTitleTerms;
