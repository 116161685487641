import React from 'react';
import { Accordion, Card, Button } from 'react-bootstrap';

const AccordionExample = ({ children }) => {
  const [activeKey, setActiveKey] = React.useState(null); // Initialize the active key

  const handleAccordionToggle = (index) => {
    setActiveKey(activeKey === index ? null : index); // Toggle the active key
  };

  return (
    <div id="accordion-example">
      <div className="bd-example">
        <Accordion id="accordion-example" activeKey={activeKey}>
          {React.Children.map(children, (child, index) => {
            if (React.isValidElement(child)) {
              return (
                <Card>
                  <Card.Header>
                    <Accordion.Toggle
                      as={Button}
                      variant="link"
                      eventKey={index.toString()}
                      onClick={() => handleAccordionToggle(index.toString())} // Handle the click event
                    >
                      {child.props.title}{' '}
                      <i
                        className={`fas fa-chevron-${
                          activeKey === index.toString() ? 'left' : 'right'
                        }`}
                      ></i>{' '}
                      {/* Use the activeKey to determine the icon */}
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey={index.toString()}>
                    <Card.Body>{child}</Card.Body>
                  </Accordion.Collapse>
                </Card>
              );
            }
            return null;
          })}
        </Accordion>
      </div>
    </div>
  );
};

export default AccordionExample;
