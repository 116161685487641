import React from 'react';

const generateTimeOptions = () => {
  const options = [];

  options.push(
    <option key="" value="" disabled>
      Välj tid
    </option>
  );

  for (let hour = 10; hour <= 19; hour++) {
    for (let minute = 0; minute < 60; minute += 30) {
      const formattedHour = hour.toString().padStart(2, '0');
      const formattedMinute = minute.toString().padStart(2, '0');
      const time = `${formattedHour}:${formattedMinute}`;
      options.push(
        <option key={time} value={time}>
          {time}
        </option>
      );
    }
  }

  return options;
};

const HourSelect = ({ selectedInterval, onIntervalChange }) => {
  const handleSelectChange = (e, type) => {
    const selectedValue = e.target.value;
    onIntervalChange(type, selectedValue);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'center', marginTop: '1rem' }}>
      <label style={{ display: 'flex', marginRight: '0.5rem' }} htmlFor="start">Från Tid:</label>
      <select
        id="start"
        name="start"
        value={selectedInterval.start}
        onChange={(e) => handleSelectChange(e, 'start')}
      >
        {generateTimeOptions()}
      </select>
      <label htmlFor="end" style={{ display: 'flex', marginRight: '0.5rem', marginLeft: '0.5rem' }}>Till Tid:</label>
      <select
        id="end"
        name="end"
        value={selectedInterval.end}
        onChange={(e) => handleSelectChange(e, 'end')}
      >
        {generateTimeOptions()}
      </select>
    </div>
  );
};

export default HourSelect;
