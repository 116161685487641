import moment from 'moment';
import { database } from '../../../firebase';
import { fetchBookingSlot } from './bookingSlot';

export async function fetchBookingData() {
  const selectedData = [];
  const id = [];
  const id1 = [];
  let employ;
  let isClosedToday;
  let closedDates = [];
  let hoursClose = {};
  let alreadyFetchClosedHoursFromFirebase = false;

  const fetchDatesFromFirebase = async () => {
    if (!alreadyFetchClosedHoursFromFirebase) {
      await database.ref('/dates').once('value', (snapshot) => {
        snapshot.forEach((childSnapshot) => {
          const childData = childSnapshot.val().date;
          closedDates.push(childData);
        });
      });
    }
  };

  const fetchClosedHoursFromFirebase = async () => {
    if (!alreadyFetchClosedHoursFromFirebase) {
      await database.ref('/hoursclose').once('value', (snapshot) => {
        snapshot.forEach((childSnapshot) => {
          const childData = childSnapshot.val();
          hoursClose = childData;
        });
      });
      alreadyFetchClosedHoursFromFirebase = true;
    }
  };

  await database.ref('/anstalld').once('value', (snapshot) => {
    snapshot.forEach((childSnapshot) => {
      employ = childSnapshot.val().value;
    });
  });

  await database.ref('/closed').once('value', (snapshot) => {
    snapshot.forEach((childSnapshot) => {
      isClosedToday = childSnapshot.val().value;
    });
  });

  await database.ref('/boka').once('value', (snapshot) => {
    snapshot.forEach((childSnapshot) => {
      const childData = childSnapshot.val().uid;
      id.push(childData);
    });
  });

  if (employ) {
    await database.ref('/boka1').once('value', (snapshot) => {
      snapshot.forEach((childSnapshot) => {
        const childData = childSnapshot.val().uid;
        id1.push(childData);
      });
    });
  }

  await fetchDatesFromFirebase();
  await fetchClosedHoursFromFirebase();

  const getCurrentTime = () => {
    const date = new Date();
    const formattedDate = date.toLocaleDateString('sv-SE', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    });

    return formattedDate;
  };

  const getFormattedDate = (ob) => {
    const dates = moment(ob.start)._d;
    const date = new Date(dates);
    const formattedDate = date.toLocaleDateString('sv-SE', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
    return formattedDate;
  };

  const timeToMinutes = (timeString) => {
    const [hours, minutes] = timeString.split(':').map(Number);
    return hours * 60 + minutes;
  };

  const isTimeInRange = (currentTime, startTime, endTime) => {
    return (
      currentTime >= timeToMinutes(startTime) &&
      currentTime <= timeToMinutes(endTime)
    );
  };

  const isOpen = (ob, start, end) =>
    isTimeInRange(ob.start.hour() * 60 + ob.start.minute(), start, end);

  const is40minutesBookingSlot = await fetchBookingSlot();

  if (is40minutesBookingSlot) {
    for (let i = moment().dayOfYear(); i < 366; i++) {
      for (let j = 10 * 60 + 20; j < 19 * 60; j += 40) {
        // Use 40 / 60 for 40 minutes interval
        let hour = Math.floor(j / 60);
        let minute = j % 60;
        let temp = i + '' + j;

        let ob = {
          start: moment({ h: hour, m: minute })
            .dayOfYear(i)
            .year(moment().year()),
          end: moment({ h: hour, m: minute })
            .add(40, 'minutes')
            .dayOfYear(i)
            .year(moment().year()),
          uid: temp,
          value: '',
          is30minuteSlot: !is40minutesBookingSlot,
          timeSlotReservedAt: getCurrentTime(),
          type: 'boka',
        };

        let ob1;

        if (!isClosedToday) {
          if (employ) {
            ob1 = {
              start: moment({ h: hour, m: minute })
                .dayOfYear(i)
                .year(moment().year()),
              end: moment({ h: hour, m: minute })
                .add(40, 'minutes')
                .dayOfYear(i)
                .year(moment().year()),
              uid: temp,
              value: '',
              is30minuteSlot: !is40minutesBookingSlot,
              timeSlotReservedAt: getCurrentTime(),
              type: 'boka1',
            };
          }

          if (
            !(
              id.includes(temp) ||
              (ob.start.weekday() === 6 && ob.start.hour() > 17) ||
              ob.start < moment() ||
              (getFormattedDate(ob) === hoursClose.date &&
                isOpen(ob, hoursClose.start, hoursClose.end)) ||
              ob.start.weekday() === 0 ||
              closedDates.includes(getFormattedDate(ob))
            )
          ) {
            selectedData.push(ob);
          }

          if (employ) {
            if (
              !(
                id1.includes(temp) ||
                (ob.start.weekday() === 6 && ob.start.hour() > 17) ||
                ob.start < moment() ||
                ob.start.weekday() === 0 ||
                closedDates.includes(getFormattedDate(ob1))
              )
            ) {
              selectedData.push(ob1);
            }
          }
        }
      }
    }
  } else {
    for (let i = moment().dayOfYear(); i < 366; i++) {
      for (let j = 10 * 60 + 20; j < (18 + 0.5) * 60; j += 30) {
        let hour = Math.floor(j / 60);
        let minute = j % 60;
        let temp = i + '' + j;

        let ob = {
          start: moment({ h: hour, m: minute })
            .dayOfYear(i)
            .year(moment().year()),
          end: moment({ h: hour, m: minute })
            .add(30, 'minutes')
            .dayOfYear(i)
            .year(moment().year()),
          uid: temp,
          value: '',
          is30minuteSlot: !is40minutesBookingSlot,
          timeSlotReservedAt: getCurrentTime(),
          type: 'boka',
        };

        let ob1;

        if (!isClosedToday) {
          if (employ) {
            ob1 = {
              start: moment({ h: hour, m: minute })
                .dayOfYear(i)
                .year(moment().year()),
              end: moment({ h: hour, m: minute })
                .add(30, 'minutes')
                .dayOfYear(i)
                .year(moment().year()),
              uid: temp,
              value: '',
              is30minuteSlot: !is40minutesBookingSlot,
              timeSlotReservedAt: getCurrentTime(),
              type: 'boka1',
            };
          }

          if (
            !(
              id.includes(temp) ||
              (ob.start.weekday() === 6 && ob.start.hour() > 17) ||
              ob.start < moment() ||
              (getFormattedDate(ob) === hoursClose.date &&
                isOpen(ob, hoursClose.start, hoursClose.end)) ||
              ob.start.weekday() === 0 ||
              closedDates.includes(getFormattedDate(ob))
            )
          ) {
            selectedData.push(ob);
          }

          if (employ) {
            if (
              !(
                id1.includes(temp) ||
                (ob.start.weekday() === 6 && ob.start.hour() > 17) ||
                ob.start < moment() ||
                ob.start.weekday() === 0 ||
                closedDates.includes(getFormattedDate(ob1))
              )
            ) {
              selectedData.push(ob1);
            }
          }
        }
      }
    }
  }

  return selectedData;
}
